.reasons-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.reasons {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 2px solid #222;
  padding: 10px 200px;
  border: 20px dotted red; /* The width of the border */
  border-radius: 10px;
}

.reason {
  font-size: 2rem;
  font-style: italic;
  padding: 100px 0;
}

.left-tilt {
  transform: rotate(20deg);
}

.right-tilt {
  transform: rotate(-20deg);
}
