.main-content {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100vw;
  height: 100vh;
}

.button {
  position: absolute;
  right: 250px;
  top: 250px;
  transform: rotate(-50deg);
  padding: 25px;
  background: pink;
  cursor: pointer;
}

.wish-text {
  font-size: 1.5rem;
  padding: 0 500px;
  text-align: center;
}

.button:hover {
  animation: shake 0.5s infinite;
}

@keyframes shake {
  0% {
    transform: rotate(-50deg) translateX(0);
  }
  25% {
    transform: rotate(-50deg) translateX(2px);
  }
  50% {
    transform: rotate(-50deg) translateX(-2px);
  }
  75% {
    transform: rotate(-50deg) translateX(2px);
  }
  100% {
    transform: rotate(-50deg) translateX(-2px);
  }
}
