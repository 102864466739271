body {
  font-family: "Grey Qo", cursive;
  font-weight: 400;
  font-style: normal;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.collection {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 4px;
  width: 100%;
  flex-wrap: wrap;
  padding: 50px;
  min-height: 4000px;
}

.photo {
  max-width: 400px;
  max-height: 400px;
  object-fit: cover;
}

.video {
  max-width: 400px;
  max-height: 400px;
  object-fit: cover;
}

.heading {
  font-size: 3rem;
  text-align: center;
}

.two-cols {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
